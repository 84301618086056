import { Box, Typography, styled } from '@mui/material'

export const TextContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
  },
}))

export const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
  marginBottom: theme.spacing(2),
})) as typeof Typography

export const Description = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
}))
