import Head from 'next/head'

const MetaTags = ({ data }: { data: IMeta }) => {
  return (
    <Head>
      <title>{data.title}</title>
      <meta property="og:title" content={data.title} />
      <meta name="description" content={data.description} />
      <meta property="og:description" content={data.description} />
      <meta property="og:image" content={data.image} />
      <meta property="og:image:alt" content={data.title} />
    </Head>
  )
}

export default MetaTags
