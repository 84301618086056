import { Box, styled } from '@mui/material'

export const BadgesContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing(6),
  [theme.breakpoints.up('lg')]: {
    marginTop: theme.spacing(3),
  },
}))
