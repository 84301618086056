import DesignRush from './Badges/DesignRush'
import { BadgesContainer } from './styled'

const BadgesList = () => {
  return (
    <BadgesContainer>
      <DesignRush />
    </BadgesContainer>
  )
}

export default BadgesList
