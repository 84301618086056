import { styled, Box, Card, CardContent } from '@mui/material'

export const AwardCardContainer = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(100, 100, 100, 0.1)',
  border: 'none',
  boxShadow: 'none',
  paddingBottom: theme.spacing(2),
  minHeight: '185px',
}))

export const AwardCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(4, 0, 2, 0),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
}))

export const AwardCardImageContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  height: '42px',
})
