/* eslint-disable react/jsx-no-target-blank */

import { SvgIcon, SvgIconProps } from '@mui/material'

const DesignRush = (props: SvgIconProps) => {
  return (
    <a
      href="https://www.designrush.com/agency/profile/the-silverlogic"
      target="_blank"
      title="The SilverLogic on DesignRush"
    >
      <SvgIcon
        sx={{
          width: '118px',
          height: '100%',
        }}
        fill="none"
        viewBox="0 0 201 270"
        {...props}
      >
        <title>The SilverLogic on DesignRush</title>
        <path
          d="M11.3604 180.37H0L4.21119 195.185L0 210H11.2625L11.3604 193.791V180.37Z"
          fill="#00539F"
        />
        <path
          d="M188.889 180.37H200.151L195.94 195.185L200.151 210H188.889V193.791L188.889 180.37Z"
          fill="#00539F"
        />
        <g filter="url(#filter0_d_303_27051)">
          <path
            d="M11.111 52.7215L99.7678 10L188.889 52.7215V216.751L100.232 260L11.111 216.751V52.7215Z"
            fill="white"
          />
        </g>
        <path
          d="M16.6664 57.5972L99.8411 17.4074L183.451 57.5972V211.907L100.277 252.593L16.6664 211.907V57.5972Z"
          fill="url(#paint0_linear_303_27051)"
        />
        <path
          d="M40.543 163.704V155.804H46.2096C51.0096 155.804 53.6763 152.504 53.6763 148.637C53.6763 144.737 51.043 141.47 46.2096 141.47H35.8096V163.704H40.543ZM45.5763 151.637H40.543V145.637H45.5763C47.443 145.637 48.843 146.77 48.843 148.637C48.843 150.47 47.443 151.637 45.5763 151.637Z"
          fill="white"
        />
        <path
          d="M74.6104 163.704L69.6104 155.237C72.0104 154.67 74.4771 152.57 74.4771 148.637C74.4771 144.504 71.6437 141.47 67.0104 141.47H56.6104V163.704H61.3437V155.804H64.8104L69.1771 163.704H74.6104ZM66.3104 151.637H61.3437V145.637H66.3104C68.2104 145.637 69.6437 146.77 69.6437 148.637C69.6437 150.504 68.2104 151.637 66.3104 151.637Z"
          fill="white"
        />
        <path
          d="M88.563 164.104C95.2963 164.104 100.196 159.304 100.196 152.604C100.196 145.904 95.2963 141.104 88.563 141.104C81.863 141.104 76.963 145.904 76.963 152.604C76.963 159.304 81.863 164.104 88.563 164.104ZM88.563 159.904C84.463 159.904 81.8297 156.737 81.8297 152.604C81.8297 148.437 84.463 145.304 88.563 145.304C92.663 145.304 95.3297 148.437 95.3297 152.604C95.3297 156.737 92.663 159.904 88.563 159.904Z"
          fill="white"
        />
        <path
          d="M108.251 163.704V154.504H119.018V150.337H108.251V145.637H119.251V141.47H103.518V163.704H108.251Z"
          fill="white"
        />
        <path d="M127.294 163.704V141.47H122.561V163.704H127.294Z" fill="white" />
        <path d="M145.841 163.704V159.537H136.441V141.47H131.708V163.704H145.841Z" fill="white" />
        <path
          d="M164.889 163.704V159.537H153.889V154.504H164.656V150.337H153.889V145.637H164.889V141.47H149.156V163.704H164.889Z"
          fill="white"
        />
        <path
          d="M89.5139 230H82.4859V228.432C86.3079 225.604 87.4279 224.652 87.4279 223.504C87.4279 222.678 86.6859 222.286 85.9159 222.286C84.8519 222.286 84.0399 222.706 83.4099 223.364L82.2759 222.048C83.1859 220.998 84.5719 220.522 85.8739 220.522C87.8759 220.522 89.4439 221.642 89.4439 223.504C89.4439 225.1 88.1279 226.486 85.7059 228.25H89.5139V230ZM95.4024 230.168C92.7284 230.168 91.5384 227.746 91.5384 225.338C91.5384 222.93 92.7284 220.522 95.4024 220.522C98.0764 220.522 99.2664 222.93 99.2664 225.338C99.2664 227.746 98.0764 230.168 95.4024 230.168ZM95.4024 228.404C96.7324 228.404 97.2364 227.06 97.2364 225.338C97.2364 223.616 96.7464 222.286 95.4024 222.286C94.0724 222.286 93.5684 223.616 93.5684 225.338C93.5684 227.06 94.0724 228.404 95.4024 228.404ZM108.27 230H101.242V228.432C105.064 225.604 106.184 224.652 106.184 223.504C106.184 222.678 105.442 222.286 104.672 222.286C103.608 222.286 102.796 222.706 102.166 223.364L101.032 222.048C101.942 220.998 103.328 220.522 104.63 220.522C106.632 220.522 108.2 221.642 108.2 223.504C108.2 225.1 106.884 226.486 104.462 228.25H108.27V230ZM116.623 230H114.635V228.068H110.169V226.5L113.879 220.662H116.623V226.318H117.827V228.068H116.623V230ZM114.635 226.318V222.426L112.115 226.318H114.635Z"
          fill="#3DDCFF"
        />
        <path d="M188.981 176.667H11.1108V206.296H188.981V176.667Z" fill="#0460B5" />
        <path
          d="M36.18 196.111C39.4698 196.111 41.7522 194.033 41.7522 190.869C41.7522 187.706 39.4698 185.612 36.18 185.612H32.0402V196.111H36.18ZM36.18 194.144H34.2753V187.58H36.18C38.3365 187.58 39.4698 189.012 39.4698 190.869C39.4698 192.648 38.2578 194.144 36.18 194.144Z"
          fill="white"
        />
        <path
          d="M51.0829 196.111V194.144H45.8884V191.767H50.9727V189.799H45.8884V187.58H51.0829V185.612H43.6533V196.111H51.0829Z"
          fill="white"
        />
        <path
          d="M56.8756 196.3C59.7089 196.3 61.0784 194.852 61.0784 192.931C61.0784 189.012 55.0497 190.13 55.0497 188.461C55.0497 187.831 55.5849 187.406 56.545 187.406C57.6154 187.406 58.7488 187.769 59.5988 188.556L60.8423 186.919C59.8034 185.958 58.4182 185.455 56.7497 185.455C54.2784 185.455 52.7673 186.903 52.7673 188.65C52.7673 192.601 58.8117 191.326 58.8117 193.183C58.8117 193.781 58.2136 194.348 56.9858 194.348C55.5376 194.348 54.3886 193.703 53.6488 192.931L52.4367 194.631C53.4126 195.623 54.8608 196.3 56.8756 196.3Z"
          fill="white"
        />
        <path d="M65.1333 196.111V185.612H62.8982V196.111H65.1333Z" fill="white" />
        <path
          d="M72.6167 196.316C74.5213 196.316 76.0167 195.529 77.0871 194.332V190.303H72.0815V192.255H74.8519V193.514C74.4269 193.907 73.5454 194.317 72.6167 194.317C70.7121 194.317 69.3269 192.853 69.3269 190.869C69.3269 188.886 70.7121 187.422 72.6167 187.422C73.7343 187.422 74.6315 188.052 75.1038 188.792L76.9612 187.784C76.1741 186.541 74.8047 185.439 72.6167 185.439C69.5473 185.439 67.0288 187.548 67.0288 190.869C67.0288 194.175 69.5473 196.316 72.6167 196.316Z"
          fill="white"
        />
        <path
          d="M88.5735 196.111V185.612H86.3383V192.207L81.4745 185.612H79.1763V196.111H81.4115V189.264L86.417 196.111H88.5735Z"
          fill="white"
        />
        <path
          d="M99.4585 196.111L97.0974 192.113C98.2307 191.845 99.3955 190.854 99.3955 188.996C99.3955 187.044 98.0576 185.612 95.8696 185.612H90.9585V196.111H93.1937V192.381H94.8307L96.8927 196.111H99.4585ZM95.539 190.413H93.1937V187.58H95.539C96.4363 187.58 97.1131 188.115 97.1131 188.996C97.1131 189.878 96.4363 190.413 95.539 190.413Z"
          fill="white"
        />
        <path
          d="M106.126 196.3C109.321 196.3 110.864 194.521 110.864 191.908V185.612H108.581V191.845C108.581 193.309 107.747 194.317 106.126 194.317C104.505 194.317 103.655 193.309 103.655 191.845V185.612H101.388V191.924C101.388 194.506 102.931 196.3 106.126 196.3Z"
          fill="white"
        />
        <path
          d="M116.978 196.3C119.811 196.3 121.18 194.852 121.18 192.931C121.18 189.012 115.152 190.13 115.152 188.461C115.152 187.831 115.687 187.406 116.647 187.406C117.717 187.406 118.851 187.769 119.701 188.556L120.944 186.919C119.905 185.958 118.52 185.455 116.852 185.455C114.38 185.455 112.869 186.903 112.869 188.65C112.869 192.601 118.914 191.326 118.914 193.183C118.914 193.781 118.316 194.348 117.088 194.348C115.64 194.348 114.491 193.703 113.751 192.931L112.539 194.631C113.515 195.623 114.963 196.3 116.978 196.3Z"
          fill="white"
        />
        <path
          d="M132.445 196.111V185.612H130.194V189.736H125.235V185.612H123V196.111H125.235V191.704H130.194V196.111H132.445Z"
          fill="white"
        />
        <path
          d="M135.804 196.284C136.497 196.284 137.079 195.702 137.079 195.009C137.079 194.317 136.497 193.734 135.804 193.734C135.112 193.734 134.529 194.317 134.529 195.009C134.529 195.702 135.112 196.284 135.804 196.284Z"
          fill="white"
        />
        <path
          d="M142.604 196.3C144.162 196.3 145.106 195.623 145.61 194.915L144.304 193.703C143.942 194.206 143.391 194.521 142.698 194.521C141.486 194.521 140.636 193.624 140.636 192.302C140.636 190.98 141.486 190.098 142.698 190.098C143.391 190.098 143.942 190.381 144.304 190.917L145.61 189.689C145.106 188.996 144.162 188.319 142.604 188.319C140.258 188.319 138.574 189.972 138.574 192.302C138.574 194.647 140.258 196.3 142.604 196.3Z"
          fill="white"
        />
        <path
          d="M150.758 196.3C153.276 196.3 154.787 194.474 154.787 192.302C154.787 190.145 153.276 188.319 150.758 188.319C148.255 188.319 146.744 190.145 146.744 192.302C146.744 194.474 148.255 196.3 150.758 196.3ZM150.758 194.521C149.514 194.521 148.822 193.498 148.822 192.302C148.822 191.121 149.514 190.098 150.758 190.098C152.001 190.098 152.71 191.121 152.71 192.302C152.71 193.498 152.001 194.521 150.758 194.521Z"
          fill="white"
        />
        <path
          d="M168.029 196.111V190.618C168.029 189.059 167.194 188.319 165.794 188.319C164.629 188.319 163.621 189.012 163.181 189.705C162.897 188.839 162.189 188.319 161.071 188.319C159.906 188.319 158.899 189.044 158.584 189.5V188.508H156.585V196.111H158.584V190.995C158.883 190.57 159.466 190.098 160.158 190.098C160.977 190.098 161.292 190.602 161.292 191.31V196.111H163.306V190.98C163.59 190.57 164.172 190.098 164.881 190.098C165.699 190.098 166.014 190.602 166.014 191.31V196.111H168.029Z"
          fill="white"
        />
        <path
          opacity="0.2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M55.5555 128.519H46.2963V127.593H55.5555V128.519Z"
          fill="white"
        />
        <path
          opacity="0.2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M155.556 128.519H146.296V127.593H155.556V128.519Z"
          fill="white"
        />
        <path
          d="M71.4034 132.222L74.7349 123.576H72.6349L70.2497 130.226L67.8516 123.576H65.7516L69.096 132.222H71.4034Z"
          fill="#3DDCFF"
        />
        <path d="M78.2815 132.222V123.576H76.4408V132.222H78.2815Z" fill="#3DDCFF" />
        <path
          d="M87.0239 132.222V130.602H82.7461V128.644H86.9332V127.024H82.7461V125.196H87.0239V123.576H80.9054V132.222H87.0239Z"
          fill="#3DDCFF"
        />
        <path
          d="M97.9388 132.222L100.402 123.576H98.3277L96.824 129.824L95.1907 123.576H93.7518L92.1055 129.824L90.5888 123.576H88.5277L91.0036 132.222H92.9611L94.4648 126.285L95.9814 132.222H97.9388Z"
          fill="#3DDCFF"
        />
        <path
          d="M110.498 132.378C113.116 132.378 115.022 130.511 115.022 127.906C115.022 125.3 113.116 123.433 110.498 123.433C107.892 123.433 105.987 125.3 105.987 127.906C105.987 130.511 107.892 132.378 110.498 132.378ZM110.498 130.744C108.903 130.744 107.879 129.513 107.879 127.906C107.879 126.285 108.903 125.067 110.498 125.067C112.092 125.067 113.129 126.285 113.129 127.906C113.129 129.513 112.092 130.744 110.498 130.744Z"
          fill="#3DDCFF"
        />
        <path
          d="M121.123 132.378C123.754 132.378 125.025 130.913 125.025 128.761V123.576H123.145V128.709C123.145 129.915 122.458 130.744 121.123 130.744C119.788 130.744 119.088 129.915 119.088 128.709V123.576H117.221V128.774C117.221 130.9 118.491 132.378 121.123 132.378Z"
          fill="#3DDCFF"
        />
        <path
          d="M134.635 132.222L132.691 128.93C133.624 128.709 134.584 127.893 134.584 126.363C134.584 124.756 133.482 123.576 131.68 123.576H127.635V132.222H129.476V129.15H130.824L132.522 132.222H134.635ZM131.408 127.53H129.476V125.196H131.408C132.146 125.196 132.704 125.637 132.704 126.363C132.704 127.089 132.146 127.53 131.408 127.53Z"
          fill="#3DDCFF"
        />
        <path
          d="M100.358 81.283C100.246 81.1711 100.246 81.283 100.358 81.283L99.7983 80.9472L99.6863 80.8353L86.4775 71.4324C86.4775 71.4324 86.3656 71.4324 86.3656 71.3205L86.1417 71.0966L86.0298 70.9847H85.8059L82 68.2981L89.4999 69.4175C89.8357 69.4175 89.9476 69.5295 90.2835 69.6414C90.3954 69.6414 90.6193 69.7533 90.6193 69.7533C90.9551 69.8653 91.067 69.9772 91.2909 70.0892C91.5148 70.2011 91.6267 70.313 91.8506 70.425C91.9625 70.5369 92.1864 70.6489 92.1864 70.7608C92.2984 70.8727 92.4103 70.8727 92.5222 70.9847C92.7461 71.0966 92.8581 71.2085 92.8581 71.3205C93.1939 71.6563 93.4177 71.9921 93.7536 72.216L99.6863 80.6114L99.7983 80.7233L100.358 81.283Z"
          fill="#2AD2FF"
        />
        <path
          d="M100.358 94.2679L99.7983 93.3724L99.6863 93.2605L82.1119 68.2981L85.9179 70.9847L86.5895 71.4324L99.7983 80.8353H99.9102L100.358 81.1711C100.358 81.1711 100.47 81.1711 100.47 81.283L101.03 80.8353L118.828 68.2981L100.918 93.3724L100.806 93.4843L100.358 94.2679Z"
          fill="url(#paint1_linear_303_27051)"
        />
        <path
          d="M100.358 77.0294L99.7981 76.1339L99.6862 76.022L95.3206 69.9773L99.6862 70.8728H99.7981L100.358 70.9847L100.917 70.8728L105.171 69.8653L100.917 76.1339V76.2458L100.358 77.0294Z"
          fill="#2AD2FF"
        />
        <path
          d="M118.939 68.1862L113.342 68.9698L111.216 69.3056C109.536 69.5295 107.969 70.425 106.962 71.8802L101.029 80.3876L100.917 80.4995L100.357 81.2831L101.029 80.6114L101.141 80.4995L100.357 81.2831L115.469 70.5369L118.939 68.1862Z"
          fill="#2AD2FF"
        />
        <path
          d="M103.157 50.4999C103.157 55.4252 94.6492 57.9998 94.6492 63.2609C94.5372 64.4922 94.8731 65.7236 95.4327 66.8429C95.4327 66.8429 92.1865 63.7087 92.1865 60.3505C92.1865 56.9923 95.3208 51.9551 95.3208 51.9551C95.3208 54.1939 96.2163 54.8655 97.1118 54.9774H97.2238C98.2312 54.9774 98.7909 54.1939 98.7909 53.0745C98.7909 51.9551 97.5596 50.8357 97.5596 48.5969C97.5596 46.4701 100.246 43 100.246 43C102.149 45.2388 103.157 47.8134 103.157 50.4999Z"
          fill="#2AF4FF"
        />
        <path
          d="M100.806 68.4101C98.1192 68.4101 96.1043 66.2833 96.1043 63.7087V63.5967C96.1043 57.8879 104.052 57.3282 104.052 49.6044C104.276 49.9402 106.403 52.8506 106.403 55.9849C106.403 59.9028 102.709 60.9102 102.709 62.8132C102.709 63.5967 103.045 64.1564 104.164 64.1564C104.276 64.1564 104.388 64.1564 104.5 64.0445C106.627 63.149 108.082 61.1341 108.194 58.7834C108.194 58.8953 109.313 68.4101 100.806 68.4101Z"
          fill="#2AF4FF"
        />
        <path
          d="M104.275 64.1564C104.387 64.1564 104.499 64.1564 104.611 64.0445C103.491 64.3803 99.3497 65.3877 99.3497 62.4773C99.3497 58.8953 105.506 58.1117 105.506 55.0894C105.506 53.1864 105.059 51.2835 104.051 49.6044C104.275 49.9402 106.402 52.8506 106.402 55.9849C106.402 59.9028 102.708 60.9102 102.708 62.8132C102.82 63.5967 103.268 64.1564 104.275 64.1564Z"
          fill="#2AD2FF"
        />
        <path
          d="M100.917 52.5148C100.917 55.3133 97.3353 55.0894 97.1114 54.9774H97.4472C98.4547 54.9774 99.0143 54.1939 99.0143 53.0745C99.0143 51.9551 97.783 50.8357 97.783 48.5969C97.783 46.4701 100.47 43 100.47 43C99.574 44.4552 99.1263 46.0223 99.0143 47.5895C99.0143 50.1641 100.917 50.388 100.917 52.5148Z"
          fill="#2AD2FF"
        />
        <path
          d="M98.6787 101.432L96.2161 99.7529H99.2384L100.246 96.7306L101.141 99.7529H104.164L101.701 101.432L102.597 104.342L100.134 102.551L97.5593 104.342L98.6787 101.432Z"
          fill="#2AD2FF"
        />
        <path
          d="M111.551 101.432L109.089 99.7529H112.111L113.118 96.7306L114.014 99.7529H117.036L114.574 101.432L115.469 104.342L113.006 102.551L110.432 104.342L111.551 101.432Z"
          fill="#2AD2FF"
        />
        <path
          d="M85.9174 101.432L83.4548 99.7529H86.4771L87.4846 96.7306L88.3801 99.7529H91.4024L88.9398 101.432L89.8353 104.342L87.3726 102.551L84.91 104.342L85.9174 101.432Z"
          fill="#2AD2FF"
        />
        <defs>
          <filter
            id="filter0_d_303_27051"
            x="1.11102"
            y="0"
            width="197.778"
            height="270"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_303_27051" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_303_27051"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_303_27051"
            x1="100.059"
            y1="23.1227"
            x2="100.059"
            y2="229.731"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0460B5" />
            <stop offset="1" stopColor="#001D51" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_303_27051"
            x1="82"
            y1="94.3149"
            x2="82"
            y2="80.5756"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.73" stopColor="#2AF4FF" />
            <stop offset="0.884" stopColor="#2AF4FF" />
          </linearGradient>
        </defs>
      </SvgIcon>
    </a>
  )
}

export default DesignRush
