import { TextContainer, Title, Description } from './styled'

export default function AwardsDescription() {
  return (
    <TextContainer>
      <Title component="h2">Awards & Partners</Title>
      <Description>
        Delve into what makes TSL special—a company that&apos;s not just about buzzwords but real,
        solid results. We&apos;ve turned heads in our industry, not just with flashy awards (though
        we&apos;ve got plenty of those!), but by consistently pushing the boundaries and delivering
        top-notch products and services. Every recognition we get is a nod to our hard work,
        commitment, and the awesome team behind it all.
      </Description>
    </TextContainer>
  )
}
